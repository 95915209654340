import React, { useMemo, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Overline, PageLayout } from '../components'
import tw from 'twin.macro'
import {
  useExtractSectionHeadersData,
  useGetIntraPageNavIsVisible,
} from './utils'
import NewsletterPresentation from '../components/newsletterPresentation'
import { DropdownMenu, DropdownOption, DropdownOptions } from './season'
import NavArrowDown from '../assets/nav_arrow_down.svg'
import { MailchimpSubscribeMobile } from "../components/MailchimpSubscribe"
import { Caption } from '../components'

export default function NewsletterContentTemplate({
  data: {
    mdx: { frontmatter, body: children, mdxAST },
    allFile,
    newsletter,
    markdownRemark: {
      frontmatter: {
        newsletterSettings: {
          ctaMsg,
          successMsg
        }
      }
    }
  },
}) {
  const [subscriptionSuccess, setSubscriptionSuccess] = useState(false)
  const [error, setError] = useState()
  const images = useMemo(
    () =>
      allFile.nodes
        .map(node => node?.childImageSharp?.fluid)
        .filter(src => !!src),
    []
  )
  const videos = useMemo(
    () =>
      allFile.nodes
        .map(node => node?.childVideoFfmpeg?.mp4)
        .filter(src => !!src),
    []
  )

  const headerImage = useMemo(() => images.filter(src => src.originalName === frontmatter.headerImage)[0], []) // prettier-ignore
  const seoImage = useMemo(() => images.filter(src => src.originalName === frontmatter.headerImage)[0], []) // prettier-ignore
  const headerVideo = useMemo(() => videos.filter(src => src.originalName?.toLowerCase() === frontmatter.headerVideo?.toLowerCase())[0], []) // prettier-ignore
  const sectionHeadersData = useExtractSectionHeadersData(mdxAST)

  const contentContainerRef = React.useRef()
  const [refElementRef, navIsVisible] = useGetIntraPageNavIsVisible(
    refElement => refElement.offsetTop + refElement.offsetHeight
  )

  const NewslettersDropdown = () => {
    const [seasonMenuIsVisible, toggleSeasonMenuIsVisible] = useState(false)
    const issueNumber = frontmatter.issueNumber
    const edition = frontmatter.edition
    return (
      <div tw="mb-4 mt-4 lg:mt-0">
        <Overline tw="text-faded text-xs lg:text-xs font-bold mb-4">
          Newsletter Archive
        </Overline>
        <div className="relative flex items-center gap-2">
          <DropdownMenu
            css={{ width: '100%' }}
            onClick={() => toggleSeasonMenuIsVisible(!seasonMenuIsVisible)}
          >
            {edition}
            <div className="w-4 h-4 pt-1 ml-2">
              <img src={NavArrowDown} alt="navigation btn" />
            </div>
          </DropdownMenu>
          <DropdownOptions
            className={` ${seasonMenuIsVisible ? 'block' : 'hidden'}`}
          >
            {newsletter.nodes.sort((b,a)=>(a.childMdx.frontmatter.issueNumber - b.childMdx.frontmatter.issueNumber)).map((nl, index) => (
              <DropdownOption
                key={nl.childMdx.frontmatter.issueNumber}
                onClick={() =>
                  (window.location.href = `/${nl.childMdx.frontmatter.slug}`)
                }
                className={`${
                  (newsletter.nodes.length -1 - index) === issueNumber - 1
                    ? 'border-solid border-l-8 border-av-purple'
                    : ''
                }`}
              >
                {nl.childMdx.frontmatter.edition}
              </DropdownOption>
            ))}
          </DropdownOptions>
        </div>
      </div>
    )
  }

  const MiniSubscribe = () => (
    <>
      <Overline tw="text-faded text-xs lg:text-xs font-bold mb-4">
        Subscribe
      </Overline>
      <Caption css={tw`text-av-text-color`}>
        {subscriptionSuccess ? successMsg : error ? error : ctaMsg}
      </Caption>
      <MailchimpSubscribeMobile onSuccess={() => setSubscriptionSuccess(true)} onError={setError} />
    </>
  )
  const socialRefers = {
    ...frontmatter.socialRefers,
    ...{
      customComponents: [<NewslettersDropdown />],
      subscribe: <MiniSubscribe />,
    },
  }
  return (
    <>
      <PageLayout
        title={frontmatter.title}
        sectionsData={sectionHeadersData}
        contentContainerRef={contentContainerRef}
        showMainNav={false}
        navIsVisible={true}
        containerProps={{
          css: { '@media print': { opacity: '1 !important' } },
        }}
        seoData={{
          description: frontmatter.subheading,
          image: seoImage,
        }}
      >
        <NewsletterPresentation
          images={images}
          headerImage={headerImage}
          headerVideo={headerVideo}
          imageCredit={frontmatter.imageCredit}
          issueNumber={frontmatter.issueNumber}
          socialRefers={socialRefers}
          children={children}
          refElementRef={refElementRef}
          contentContainerRef={contentContainerRef}
        />
      </PageLayout>
    </>
  )
}

export const pageQuery = graphql`
  query FeatureContentQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      mdxAST
      frontmatter {
        issueNumber
        edition
        headerImage
        headerVideo
        imageCredit
        title
        pdfFile
        audioFile
        date
        subheading
        leftSubscribeText
        socialRefers {
          title
          notes
          shareText
          image
        }
      }
    }
    allFile(filter: { relativeDirectory: { eq: "contents" } }) {
      nodes {
        name
        ext
        publicURL
        childImageSharp {
          fluid(sizes: "100vw") {
            ...GatsbyImageSharpFluid
            originalName
            presentationWidth
            presentationHeight
          }
        }
        childVideoFfmpeg {
          mp4: transcode(
            maxWidth: 800
            maxHeight: 532
            fileExtension: "mp4"
            codec: "libx264"
          ) {
            width
            src
            presentationMaxWidth
            presentationMaxHeight
            originalName
            height
            fileExtension
            aspectRatio
          }
        }
      }
    }
    newsletter: allFile(filter: { relativeDirectory: { eq: "newsletter" } }) {
      nodes {
        name
        publicURL
        childMdx {
          frontmatter {
            issueNumber
            edition
            slug
          }
        }
      }
    }
    markdownRemark(frontmatter: { filterKey: { eq: "site-settings" } }) {
      frontmatter {
        newsletterSettings {
          bgImage
          ctaMsg
          successMsg
        }
      }
    }
  }
`
