import React from 'react'
import { Link } from 'gatsby'
import Image from 'gatsby-image'
import { Video } from 'gatsby-video'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import {
  Container,
  PrimaryContent,
  H4,
  BaseMDXProviderNew,
  GlossarySidebarProvider,
  GlossaryTermLink,
  InlinePhoto,
  QuoteImagePhoto,
  FullPhoto,
  QuotePhoto,
  InlineVideo,
  PodcastBox,
  SubscribeBox,
  Tagline,
  SubsectionHeader,
  Body1,
  SubsectionIndented,
  PhotoModuleImagesProvider
} from '../components'
import LogoDark from '../assets/aventine_brand_logo-dark.svg'
import { cssMq } from '../components/mediaquery'
import SectionHeader from '../components/PageLayout/SectionHeaderNew'
import tw from 'twin.macro'

const PageHeader = ({ children }) => {
  return (
    <Container>
      <PrimaryContent className="pt-2 pb-6">
        <H4 className="text-2xl">{children}</H4>
      </PrimaryContent>
    </Container>
  )
}

const Subtitle = ({ text }) => (
  <>
    <Container>
      <PrimaryContent className="pt-2">
        <div
          className="text-av-purple text-xs lg:text-lg"
          css={{ weight: 400 }}
        >
          {text}
        </div>
      </PrimaryContent>
    </Container>
  </>
)

export const components = {
  SectionHeader,
  PageHeader,
  Subtitle,
  InlinePhoto,
  QuoteImagePhoto,
  FullPhoto,
  QuotePhoto,
  PodcastBox,
  SubscribeBox,
  GlossaryTermLink,
  InlineVideo,
  SubsectionHeader,
  SubsectionIndented,
  inlineCode: ({ children, ...props }) => {
    return (
      <span
        className="text-faded italic"
        css={{ fontFamily: 'inherit', fontSize: 'inherit' }}
        {...props}
      >
        {children}
      </span>
    )
  },
  ul: props => (
    <PrimaryContent as={Body1}>
      <div>{props.children}</div>
    </PrimaryContent>
  ),
  li: props => <li className="mx-9 mb-6" {...props} />,
  blockquote: props => (
    <div css={{ display: 'flex' }}>
      <div tw="text-av-purple text-5xl mr-4" css={{ lineHeight: '1' }}>
        “
      </div>
      <div {...props} />
    </div>
  ),
}

const NewsletterPresentation = React.forwardRef(
  ({
    images,
    headerImage,
    headerVideo,
    imageCredit,
    issueNumber,
    socialRefers,
    children,
    refElementRef,
    contentContainerRef,
  }) => {
    return (
      <>
        <div
          className="hidden w-full items-center"
          css={cssMq({
            height: ['60px', '60px', '72px', '120px', '120px'],
            '@media print': { display: 'flex' },
          })}
        >
          <div className=" border-t border-black w-1/2" />
          <Link
            to="/"
            className="flex"
            css={{
              padding: '0 1.875rem',
              height: ['24px', '24px', '30px', '50px', '50px'],
            }}
          >
            <img src={LogoDark} alt="Aventine Logo" className="h-full w-full" />
          </Link>
          <div className=" border-t border-black w-1/2" />
        </div>
        <div css={{ marginTop: '80px' }} />
        <Container className="mt-6 md:mt-10" ref={refElementRef}>
          <PrimaryContent
            as={Tagline}
            className="mb-10 lg:mb-14 border-t border-b border-solid border-black"
          >
            Newsletter /&nbsp;
            <span className="font-bold">Issue No. {issueNumber}</span>
          </PrimaryContent>
          <PrimaryContent className="mb-2">
            {
              headerVideo ? (
                <Video
                  autoPlay
                  muted
                  loop
                  sources={[headerVideo]}
                  css={{ borderRadius: '1rem' }}
                />
              ) : (
                <Image
                  fluid={headerImage}
                  alt="page art"
                  css={{ borderRadius: '1rem' }}
                />
              )
            }
          </PrimaryContent>
        </Container>
        {imageCredit && (
          <Container>
            <PrimaryContent className="mb-5 lg:mb-16 text-sm">{imageCredit}</PrimaryContent>
          </Container>
        )}
        <PhotoModuleImagesProvider images={images}>
          <GlossarySidebarProvider>
            <BaseMDXProviderNew
              ref={contentContainerRef}
              components={components}
              socialRefers={socialRefers}
            >
              <MDXRenderer>{children}</MDXRenderer>
            </BaseMDXProviderNew>
          </GlossarySidebarProvider>
        </PhotoModuleImagesProvider>
      </>
    )
  }
)
export default NewsletterPresentation
